import {
  LazyLoadingHandler_default
} from "./chunk.ZXFS3F4J.js";
import {
  Spaceable
} from "./chunk.G3SZKTQJ.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  h,
  ke
} from "./chunk.FCKNEYTX.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-interactive-animation/jb-interactive-animation.ts
var JbInteractiveAnimation = class extends Spaceable(h) {
  firstUpdated() {
    LazyLoadingHandler_default.observe(
      this,
      async () => {
        await import("./lottie-player.esm.E5CIQAMJ.js");
      },
      "approachingViewport"
    );
    this._lottiePlayer?.addEventListener("play", () => {
      this.links?.forEach((link) => {
        this._lottiePlayer?.shadowRoot?.querySelectorAll(link.selector).forEach((element) => {
          element.style.cursor = "pointer";
          element.addEventListener("click", () => {
            window.location.href = link.href;
          });
        });
      });
    });
  }
  render() {
    return ke`
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="${this.animationFile}"
      ></lottie-player>
    `;
  }
};
__decorateClass([
  n()
], JbInteractiveAnimation.prototype, "animationFile", 2);
__decorateClass([
  n({ type: Array })
], JbInteractiveAnimation.prototype, "links", 2);
__decorateClass([
  e("lottie-player")
], JbInteractiveAnimation.prototype, "_lottiePlayer", 2);
JbInteractiveAnimation = __decorateClass([
  customElement("jb-interactive-animation")
], JbInteractiveAnimation);

export {
  JbInteractiveAnimation
};
